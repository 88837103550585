import styled from 'styled-components';
import { motion } from 'framer-motion';


export const HomeOpinionSection = styled(motion.section)`
  margin-bottom: 10rem;
  margin-left: 0;

  ${({theme}) => theme.media.laptopXL} {
    margin-bottom: 10rem;
    margin-left: 5rem;
  }
  ${({theme}) => theme.media.desktop} {
    margin-bottom: 14rem;
  }
`


export const ContentTitle = styled.h3`
  font-size: 1.9rem;
  margin: 3rem 0 1rem;
  line-height: 1.4;

  ${({theme}) => theme.media.laptopXL} {
    font-size: 2.3rem;
    margin: 2rem 0;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  ${({theme}) => theme.media.laptopXL} {
    flex-direction: row;
    padding: 2rem 0;
  }
`


export const ContentSlider = styled.div`
  width: 100%;

  button.slick-next:before, button.slick-prev:before {
    color: ${({theme}) => theme.color} !important;
  }
  .slick-dots li button:before {
    color: ${({theme}) => theme.color} !important;
  }

  ${({theme}) => theme.media.laptopXL} {
    width: 60%;
  }
`

export const SliderBox = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    font-size: 1.2rem;
    line-height: 1.4;

    ${({theme}) => theme.media.laptopXL} {
      font-size: 1.3rem;
      line-height: 1.4;
    }
  }

  h4 {
    font-size: 1.6rem;
    margin-top: 1rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`


export const ContentImg = styled.div`
  position: relative;
  transform: translate(0, 0);
  width: 100%;
  height: 20rem;

  ${({theme}) => theme.media.laptop} {
    height: 25rem;
    }

  .first-img {
    position: absolute !important;
    top: 0;
    left: 5%;
    width: 85%;
    filter: drop-shadow(3px 3px 10px rgba(20,20,20,0.4));

    ${({theme}) => theme.media.laptop} {
      width: 40%;
      left: 35%;
    }

    ${({theme}) => theme.media.laptopXL} {
      width: 80%;
      left: 5%;
    }

    ${({theme}) => theme.media.desktop} {
      width: 90%;
      left: 0;
    }
  }

  .secound-img {
    position: absolute !important;
    bottom: 0;
    right: 0;
    width: 50%;
    filter: drop-shadow(3px 3px 10px rgba(20,20,20,0.4));

    ${({theme}) => theme.media.laptop} {
      width: 30%;
      right: 5%;
    }

    ${({theme}) => theme.media.laptopXL} {
      width: 50%;
      right: 5%;
    }

    ${({theme}) => theme.media.desktop} {
      width: 55%;
      right: 0;
      bottom: -20%;
    }
  }

  .third-img {
    position: absolute !important;
    top: -15%;
    left: 25%;
    width: 45%;
    filter: drop-shadow(3px 3px 10px rgba(20,20,20,0.4));

    ${({theme}) => theme.media.laptop} {
      width: 25%;
      left: 20%;
    }

    ${({theme}) => theme.media.laptop} {
      width: 40%;
      left: -10%;
    }

    ${({theme}) => theme.media.desktop} {
      width: 45%;
      left: -15%;
    }
  }

  /* img {
    width: 100%;
    filter: drop-shadow(3px 3px 10px rgba(20,20,20,0.4));

    ${({theme}) => theme.media.laptop} {
      width: 65%;
    }

    ${({theme}) => theme.media.laptopXL} {
      width: 100%;
    }    
  } */

  ${({theme}) => theme.media.laptop} {
    transform: translate(-20%, 0);
  }
  ${({theme}) => theme.media.laptopXL} {
    transform: translate(-20%, 0) scale(1.05);
  }
`