import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'

import { Container } from '../globalStyles.css'
import { HomeAboutSection, Content, ContentTitle, ContentText } from './homeAbout.css'


const HomeAbout = () => {
  const animation = useAnimation();
  const [contentRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-15%',
  })

  useEffect(() => {
    if(inView) {
      animation.start('visible')
    }
  }, [animation, inView])

  return (
    <HomeAboutSection
      ref={contentRef}
      animate={animation}
      initial='hidden'
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: .6, ease: [0.6, 0.05, -0.01, 0.9] }
        },
        hidden: {
          opacity: 0,
          y: '50px',
        }
      }}
    >
      <Container>
        <Content>
          <ContentTitle>
            Wspaniałe historie warto zapisać - 
          </ContentTitle>
          <ContentText>
            Streaming daje możliwość na pokazanie wydarzenia lub miejsca szerokiej publiczności przy niskich nakładach. Organizowane wydarzenie kulturalne, sportowe, czy biznesowe mogą dzięki streamingowi zaistnieć online.
          </ContentText>
        </Content>
      </Container>
    </HomeAboutSection>
  )
}

export default HomeAbout
