import styled from 'styled-components';
import { motion } from 'framer-motion';


export const Banner = styled.div`
  background: ${({theme}) => theme.background};
  height: 70vh;
  width: 100%;
  position: relative;
  margin-bottom: 9.5rem;

  ${({theme}) => theme.media.laptop} {
    height: 100vh;
    margin-bottom: 14.5rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    margin-bottom: 18.5rem;
  }
`;


export const Video = styled.div`
  height: 100%;
  width: 100%;

  video {
    object-fit: cover;
  }
`;


export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: block;

  @media (max-width: 1000px) {
    display: none;
  }
`;


export const BannerTitle = styled(motion.h1)`
  position: absolute;
  bottom: -5rem;
  left: -10px;
  color: ${({theme}) => theme.colors.mainBlue};
  pointer-events: none;

  ${({theme}) => theme.media.tabletXL} {
    left: -20px;
    bottom: -5rem;
  }
  ${({theme}) => theme.media.laptopXL} {
    left: -20px;
    bottom: -5rem;
  }
  ${({theme}) => theme.media.desktop} {
    left: -30px;
    bottom: -6rem;
  }
`;


export const Headline = styled(motion.span)`
  display: block;
  font-size: 4.7rem;
  font-weight: 900;
  line-height: 0.86;
  letter-spacing: -0.5rem;
  text-transform: uppercase;

  ${({theme}) => theme.media.tabletXL} {
    font-size: 12rem;
    line-height: 0.76;
    letter-spacing: -1.1rem;
  }
  ${({theme}) => theme.media.laptopXL} {
    font-size: 16rem;
    line-height: 0.76;
    letter-spacing: -1.4rem;
  }
  ${({theme}) => theme.media.desktop} {
    font-size: 18rem;
    letter-spacing: -1.8rem;
  }
`;