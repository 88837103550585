import React from "react"

import Layout from "../components/layout"
import HomeBanner from '../components/homePage/homeBanner'
import HomeAbout from '../components/homePage/homeAbout'
import HomeFeatured from '../components/homePage/homeFeatured'
import HomeOpinion from '../components/homePage/homeOpinion'

import { useGlobalStateContext, useGlobalDispatchContext } from '../context/globalContext'

import SEO from "../components/seo"


const HomePage = (props) => {

  const { cursorStyles } = useGlobalStateContext()
  const dispatch = useGlobalDispatchContext()

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType })
  }

  return (
    <Layout>
      <SEO title="Strona główna - BluStreamTV" />

      <HomeBanner onCursor={onCursor}/>
      <HomeAbout />
      <HomeFeatured onCursor={onCursor}/>
      <HomeOpinion onCursor={onCursor}/>

    </Layout>
  )
}

export default HomePage
