import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'

import FeatuerWideo from '../../assets/video/filharmonia-pomorska.mp4'

import { Container, Flex } from '../globalStyles.css'
import { HomeFeaturedSection, FeaturedContend, FeaturedVideo, FeaturedProjedts, FeaturedArrow } from './homeFeatured.css'

const HomeFeatured = ({ onCursor }) => {

  const animation = useAnimation();
  const [featuredRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-20%',
  })

  useEffect(() => {
    if(inView) {
      animation.start('visible')
    }
  }, [animation, inView])

  return (
    <HomeFeaturedSection>
      <Container>
     
          <FeaturedContend 
            ref={featuredRef}
            animate={animation}
            initial='hidden'
            variants={{
              visible: {
                opacity: 1,
                y: 0,
                transition: { duration: .6, ease: [0.6, 0.05, -0.01, 0.9] }
              },
              hidden: {
                opacity: 0,
                y: '50px',
              }
            }}
          >
            <Flex>
              <h3>Organizują Państwo ciekawe wydarzenie lub imprezę sportową?</h3>
            </Flex>
            <Flex spaceBetween>
              <div className="meta">
                <h4>Oferujemy Państwu streaming eventowy <span style={{color: '#46BAE8', fontWeight: 600}}>BluStream</span><span style={{color: '#FF0000', fontWeight: 600}}>TV</span>. Kompleksowa obsługa transmisji w Internecie.</h4>
                <h4>Posiadamy profesjonalny zespół operatorów i montażystów dysponująch nowoczesnym sprzętem. </h4>
              </div>
            </Flex>
          </FeaturedContend>

          <FeaturedVideo>
            <video autoPlay loop muted>
              <source src={FeatuerWideo} type="video/mp4"></source>
            </video>
          </FeaturedVideo>

          <FeaturedArrow>
            <Link
            to='/kontakt'
              onMouseLeave={onCursor}
              onMouseEnter={() => onCursor('hovered')}
            >
              <h2 className="featured-title">
                <span style={{color: '#46BAE8'}}>BluStream</span><span style={{color: '#FF0000'}}>TV</span>
                <span className="arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 57" >
                    <path
                    d="M33 34H0V24h81.429L66 7.884 73.548 0l19.877 20.763.027-.029L101 28.618 73.829 57l-7.548-7.884L80.753 34H33z"
                    fill="#000"
                    fillRule="evenodd"
                    ></path>
                    </svg>
                </span>
              </h2>
            </Link>
          </FeaturedArrow>
       
      </Container>
      <Container>
        <FeaturedProjedts>
          <Flex flexEnd>
            <Link to='/projekty'>
              <button
                onMouseLeave={onCursor}
                onMouseEnter={() => onCursor('hovered')}
              >
                <span>Nasze Projekty</span><FontAwesomeIcon icon={faEllipsisV} />
              </button>
            </Link>
          </Flex>
        </FeaturedProjedts>
      </Container>
    </HomeFeaturedSection>
  )
}

export default HomeFeatured
