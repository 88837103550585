import styled from 'styled-components';
import { motion } from 'framer-motion';


export const HomeFeaturedSection = styled.section`
  position: relative;
  margin-bottom: 0;

  a {
    position: relative;
    margin-bottom: 6rem;
    display: block;
  }

  ${({theme}) => theme.media.laptopXL} {
    margin-bottom: 5rem;
  }
`


export const FeaturedContend = styled(motion.div)`
  width: 100%;
  padding: 1rem 0;
  color: ${({theme}) => theme.color};

  h3 {
    font-size: 1.9rem;
    margin: 2rem 0;
    width: 100%;

    ${({theme}) => theme.media.laptop} {
      width: 70%;
      font-size: 2.1rem;
    }

    ${({theme}) => theme.media.laptopXL} {
      font-size: 2.3rem;
      margin: 2rem 0;
      width: 60%;
    }
  }

  .meta {
    display: flex;
    flex-direction: column;

    h4 {
      font-size: 1.3rem;
      line-height: 1.4;
      font-weight: 400;
      margin-bottom: 1rem;

      ${({theme}) => theme.media.laptopXL} {
        margin-bottom: 0;
      }
    }

    ${({theme}) => theme.media.laptop} {
      flex-direction: row;
    }
  }

  ${({theme}) => theme.media.laptopXL} {
    width: 100%;
    padding: 3rem 1rem;
    margin-left: 4rem;
  }
  
`

export const FeaturedArrow = styled.div`
  .featured-title {
    position: absolute;
    bottom: 2.5rem;
    left: -1.5rem;
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 4rem;
    margin: 0;

    ${({theme}) => theme.media.laptop} {
      bottom: 2.5rem;
      left: 1rem;
      font-size: 4.5rem;
      line-height: 6rem;
      margin: 0;
    }

    ${({theme}) => theme.media.laptopXL} {
      left: 3rem;
      font-size: 5rem;
    }

    .arrow {
      width: 5rem;
      height: 3rem;
      display: block;
      position: relative;
      overflow: hidden;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 4rem;

        ${({theme}) => theme.media.laptop} {
          width: 5rem;
        }

        path {
          fill: ${({theme}) => theme.color};
        }
      }
    }
  }
`


export const FeaturedVideo = styled.div`
  z-index: -1;
  width: 100%;
  height: auto;
  display: block;
  overflow: hidden;
  
  ${({theme}) => theme.media.laptopXL} {
    z-index: -1;
    width: 100%;
    height: 550px;
    top: 14rem;
  }

  video {
    width: 100%;
    height: 100%;
  }
`


export const FeaturedProjedts = styled.div`
  button {
    display: flex;
    justify-content: space-between;
    color: ${({theme}) => theme.colors.mainWhite};
    background: ${({theme}) => theme.colors.mainRed};
    padding: .5rem 1.3rem;
    font-size: 1.4rem;
    text-align: left;
    line-height: 1;
    font-weight: 400;
    border: none;

    span {
      margin-right: 3rem;
      display: block;
    }
  }
`
