import React, { useRef, useEffect } from 'react'
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { useInView } from 'react-intersection-observer'
import { useAnimation } from 'framer-motion'
// import gsap from 'gsap'
import Img from "gatsby-image"

// import OpinionImage from '../../images/opinionImage.svg'


import { Container } from '../globalStyles.css'
import { HomeOpinionSection, ContentTitle, ContentWrapper, ContentSlider, SliderBox, ContentImg } from './homeOpinion.css'


const HomeOpinion = ({ onCursor }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryOpinions {
      site {
        siteMetadata {
          opinionsArr {
            id
            texts
            companyName
          }
        }
      }
      mediacjeImage: file(relativePath: { eq: "tydzien-mediacji.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vintageImage: file(relativePath: { eq: "vintage-photo-festival.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      festynImage: file(relativePath: { eq: "festyn-charytatywny.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  const imgOptionWrapper = useRef(null);

  const animation = useAnimation();
  const [opinionRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-20%',
  })

  useEffect(() => {
    if(inView) {
      animation.start('visible')

      // const div = imgOptionWrapper.current;
      // const [elements] = imgOptionWrapper.current.children;

      // const phone = elements.getElementById('MainPhone')
      // const leftIcons = elements.getElementById('LeftIcons')
      // const rightIcons = elements.getElementById('RightIcons')

      // const leftIconsItems = [...leftIcons.children]
      // const rightIconsItems = [...rightIcons.children]

      // gsap.set(leftIconsItems, {autoAlpha: 0})
      // gsap.set(rightIconsItems, {transformOrigin: '50%, 50%', scale: 0, autoAlpha: 0, y: '100'})

      // const tlHome = gsap.timeline();

      // tlHome.to(div, {opacity: 1}, 0.3)
      //   .fromTo(phone, {autoAlpha: 0, x: '-50'}, {duration: .6, autoAlpha: 1, x: 0}, .4)
      //   .to(leftIconsItems, {duration: 1, autoAlpha: 1, stagger: 0.1})
      //   .to(rightIconsItems, {duration: 1, autoAlpha: 1, scale: 1, y: 0, stagger: 0.1}, '-=1')
    }
  }, [animation, inView])

  return (
    <HomeOpinionSection
      ref={opinionRef}
      animate={animation}
      initial='hidden'
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { duration: .6, ease: [0.6, 0.05, -0.01, 0.9] }
        },
        hidden: {
          opacity: 0,
          y: '50px',
        }
      }}
    >
      <Container>
        <ContentWrapper>

        <ContentImg ref={imgOptionWrapper}>
          {/* <OpinionImage /> */}
          <Img className="first-img" fluid={data.mediacjeImage.childImageSharp.fluid} />
          <Img className="secound-img" fluid={data.vintageImage.childImageSharp.fluid} />
          <Img className="third-img" fluid={data.festynImage.childImageSharp.fluid} />
        </ContentImg>

        <ContentSlider
          onMouseLeave={onCursor}
          onMouseEnter={() => onCursor('hovered')}
        >
          <ContentTitle>
            Nasza współpraca z klientami - 
          </ContentTitle>
          <Slider
            speed={1500}
            autoplay={true}
            autoplaySpeed={3000}
            slidesToShow={1}
            slidesToScroll={1}
            infinite={true}
            dots={true}
            pauseOnHover={true}
            pauseOnFocus={true}
          >

            {data.site.siteMetadata.opinionsArr.map(opinion => (
              <SliderBox key={opinion.id}>
                <p><FontAwesomeIcon icon={faQuoteRight} style={{color: '#46BAE8'}} /> {opinion.texts}</p>
                <h4>{opinion.companyName}</h4>
              </SliderBox>
            ))}

          </Slider>
        </ContentSlider>
        
        </ContentWrapper>
      </Container>
    </HomeOpinionSection>
  )
}

export default HomeOpinion
