import styled from 'styled-components';
import { motion } from 'framer-motion';


export const HomeAboutSection = styled(motion.section)`
  margin-bottom: 4rem;

  ${({theme}) => theme.media.laptopXL} {
    margin-bottom: 8rem;
  }
`


export const Content = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 400;

  ${({theme}) => theme.media.laptop} {
    width: 70%;
    font-size: 1.6rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    width: 60%;
    font-size: 2rem;
    margin-left: 5rem;
  }
`


export const ContentTitle = styled.h2`
  font-size: 1.9rem;
  font-weight: 900;
  margin-bottom: .5rem;

  ${({theme}) => theme.media.laptop} {
    font-size: 2.1rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    font-size: 2.3rem;
  }
`


export const ContentText = styled.p`
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.4;

  ${({theme}) => theme.media.laptop} {
    font-size: 1.4rem;
  }

  ${({theme}) => theme.media.laptopXL} {
    font-size: 1.6rem;
  }
`