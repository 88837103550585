import React, { useEffect, useRef } from 'react'

import ShortMovie from '../../assets/video/Camera2.mp4'

import useWindowSize from '../../hooks/useWindowSize';
import { Banner, Video, Canvas, BannerTitle, Headline } from './homeBanner.css'

// context
import { useGlobalStateContext } from '../../context/globalContext'

const HomeBanner = ({onCursor}) => {

  const size = useWindowSize()
  let canvas = useRef(null)
  const { currentTheme } = useGlobalStateContext()

  useEffect(() => {
    let renderingElement = canvas.current;
    let drawingElement = renderingElement.cloneNode();

    let drawingCtx = drawingElement.getContext('2d');
    let renderingCtx = renderingElement.getContext('2d');

    let lastX;
    let lastY;

    let moving = false;

    renderingCtx.globalCompositeOperation = 'source-over'
    renderingCtx.fillStyle = currentTheme === 'dark' ? '#141414' : '#FEFEFE'
    renderingCtx.fillRect(0, 0, size.windowWidth, size.windowHeight)

    renderingElement.addEventListener('mouseover', e => {
      moving = true;
      lastX = e.pageX - renderingElement.offsetLeft;
      lastY = e.pageY - renderingElement.offsetTop;
    })

    renderingElement.addEventListener('mouseup', e => {
      moving = false;
      lastX = e.pageX - renderingElement.offsetLeft;
      lastY = e.pageY - renderingElement.offsetTop;
    })

    renderingElement.addEventListener('mousemove', e => {
      moving = true;
      if(moving) {
        drawingCtx.globalCompositeOperation = 'source-over';
        renderingCtx.globalCompositeOperation = 'destination-out';

        let currentX = e.pageX - renderingElement.offsetLeft;
        let currentY = e.pageY - renderingElement.offsetTop;

        drawingCtx.lineJoin = 'round'
        drawingCtx.moveTo(lastX, lastY)
        drawingCtx.lineTo(currentX, currentY)
        drawingCtx.closePath()
        drawingCtx.lineWidth = 200
        drawingCtx.stroke()
        lastX = currentX;
        lastY = currentY;
        renderingCtx.drawImage(drawingElement, 0, 0)
      }
    })

  }, [currentTheme, size.windowHeight, size.windowWidth])

  const parent = {
    initial: {y: 800},
    animate: {
      y: 0,
      transition: {
        staggerChildren: .2,
      },
    },
  }

  const child = {
    initial: {y: 800},
    animate: {
      y: 0,
      transition: {
        duration: 1,
        ease: [0.6, 0.05, -0.01, 0.9],
      },
    },
  }

  return (
    <Banner>
      <Video>
        {/* <video 
          height='100%'
          width='100%'
          loop
          autoPlay
          src={require('../../assets/video/Camera2.mp4')}
        /> */}

        <video autoPlay loop muted height='100%' width='100%'>
          <source src={ShortMovie} type="video/mp4"></source>
        </video>
      </Video>
      <Canvas 
        height={size.windowHeight} 
        width={size.windowWidth} 
        ref={canvas} 
        onMouseLeave={onCursor}
        onMouseEnter={() => onCursor('hovered')}
      />
      <BannerTitle variants={parent} initial='initial' animate='animate'>
        <Headline variants={child}>Video</Headline>
        <Headline variants={child}>Striming</Headline>
      </BannerTitle>
    </Banner>
  )
}

export default HomeBanner