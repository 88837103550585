import { useState, useEffect } from 'react';


// let defaultHeight
// let defaultWidth

// if (typeof window !== `undefined`) {
//   defaultHeight = window.innerHeight
//   defaultWidth = window.innerWidth
// }

export default function useWindowSize() {


  const [windowSize, setDimensions] = useState({
    windowHeight: 950,
    windowWidth: 2560,
  })

  useEffect(() => {
    function handler() { 
      setDimensions({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    })
  }

    window.addEventListener(`resize`, handler)
    return () => window.removeEventListener(`resize`, handler)
  }, [])

  return windowSize
}